<template>
  <main class="user-detail-popup memberMsg-popup">
    <div class="wrapper fx-col">
      <div class="user-wrapper-outer">
        <div class="wrapper-title">
          <span>머니변동내역</span>
          <div class="search-check-wrap">
                <input type="checkbox" id="page-select-1" value="cash" checked="" onchange="logListSeletor()">
                <label for="page-select-1">
                    <i class="fas fa-check"></i>
                    <span>충/환전</span>
                </label>
                <input type="checkbox" id="page-select-2" value="bet" checked="" onchange="logListSeletor()">
                <label for="page-select-2">
                    <i class="fas fa-check"></i>
                    <span>베팅</span>
                </label>
                <input type="checkbox" id="page-select-3" value="point" checked="" onchange="logListSeletor()">
                <label for="page-select-3">
                    <i class="fas fa-check"></i>
                    <span>{{ $t('table.body.point') }}</span>
                </label>
            </div>
        </div>
        <div class="bottom-wrap">
          <div class="square-table-wrap">
            <table class="square-table ok">
              <thead>
                <tr>
                  <th width="30%">
                    <span class="table-sort up" onclick="sortChange(this)"
                      >{{ $t('table.head.date') }}</span>
                  </th>
                  <th width="19%">
                    <span class="table-sort up" onclick="sortChange(this)"
                      >금액</span>
                  </th>
                  <th width="19%">
                    <span class="table-sort up" onclick="sortChange(this)"
                      >처리 후 머니</span>
                  </th>
                  <th>
                    <span class="table-sort up" onclick="sortChange(this)"
                      >{{ $t('table.head.type') }}</span>
                  </th>
                  <th>
                    <span class="table-sort up" onclick="sortChange(this)"
                      >{{ $t('table.head.content') }}</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td><span>2021-10-07 17:35:04.0</span></td>
                  <td><span class="fc-red">500,000</span></td>
                  <td><span>500,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">베팅성공</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-07 17:24:09.0</span></td>
                  <td><span class="fc-red">100,000</span></td>
                  <td><span>1,100,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">포인트 전환</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-07 17:15:13.0</span></td>
                  <td><span class="fc-red">1,000,000</span></td>
                  <td><span>1,000,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">충전</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-06 22:01:35.0</span></td>
                  <td><span class="fc-red">500,000</span></td>
                  <td><span>1,500,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">베팅성공</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-06 22:01:06.0</span></td>
                  <td><span class="fc-red">1,000,000</span></td>
                  <td><span>1,500,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">베팅성공</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-06 21:57:39.0</span></td>
                  <td><span class="fc-red">900,000</span></td>
                  <td><span>1,900,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">베팅성공</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-06 21:57:11.0</span></td>
                  <td><span class="fc-red">600,000</span></td>
                  <td><span>1,900,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">베팅성공</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-06 21:47:44.0</span></td>
                  <td><span class="fc-red">100,000</span></td>
                  <td><span>1,600,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">포인트 전환</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-06 21:46:44.0</span></td>
                  <td><span class="fc-red">1,500,000</span></td>
                  <td><span>1,500,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">충전</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-04 22:01:17.0</span></td>
                  <td><span class="fc-red">4,000,000</span></td>
                  <td><span>7,100,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">베팅성공</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-04 22:00:45.0</span></td>
                  <td><span class="fc-red">3,900,000</span></td>
                  <td><span>5,100,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">베팅성공</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-04 22:00:17.0</span></td>
                  <td><span class="fc-red">2,200,000</span></td>
                  <td><span>3,200,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">베팅성공</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-04 21:56:15.0</span></td>
                  <td><span class="fc-red">100,000</span></td>
                  <td><span>2,100,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">포인트 전환</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-04 21:51:20.0</span></td>
                  <td><span class="fc-red">2,000,000</span></td>
                  <td><span>2,000,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">충전</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-03 21:19:35.0</span></td>
                  <td><span class="fc-red">100,000</span></td>
                  <td><span>2,100,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">포인트 전환</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-03 21:14:27.0</span></td>
                  <td><span class="fc-red">2,000,000</span></td>
                  <td><span>2,000,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">충전</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-02 18:34:35.0</span></td>
                  <td><span class="fc-red">100,000</span></td>
                  <td><span>1,100,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">포인트 전환</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-10-02 18:32:18.0</span></td>
                  <td><span class="fc-red">1,000,000</span></td>
                  <td><span>1,000,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">충전</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-09-30 16:45:07.0</span></td>
                  <td><span class="fc-red">975,000</span></td>
                  <td><span>975,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">베팅성공</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-09-30 16:43:34.0</span></td>
                  <td><span class="fc-red">2,200,000</span></td>
                  <td><span>3,200,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">베팅성공</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-09-30 16:16:47.0</span></td>
                  <td><span class="fc-red">100,000</span></td>
                  <td><span>2,100,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">포인트 전환</a></span>
                  </td>
                  <td><span></span></td>
                </tr>

                <tr>
                  <td><span>2021-09-30 16:12:35.0</span></td>
                  <td><span class="fc-red">2,000,000</span></td>
                  <td><span>2,000,000</span></td>
                  <td class="logDiv">
                    <span><a href="#">충전</a></span>
                  </td>
                  <td><span></span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// import SearchBar from '@/components/main/top/SearchBar.vue'

export default {
  name: 'AccHistory'
}
</script>

<style scoped>
.active .btn-t-inner {
  display: none;
}
.fstColumn.userList .levelColor {
  display: block;
}

.box-hideOpen .levelColor {
  background: rgb(255, 218, 38);
  width: 8px;
}
.seletAndDelete {
  display: flex;
  align-items: center;
}
.msg-all-sel {
  margin-right: 4px !important;
  height: 30px;
  box-sizing: border-box;
}
#memId {
  margin-right: 4px;
}
.btn-state-change.cancle {
  margin-right: 4px;
}
.user-detail-popup {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 565px;
  margin: 0;
  box-sizing: border-box;
}
.user-detail-popup select {
  font-size: 1em;
}
.wrapper-title .btn-save {
  padding: 0 12px;
}
.button-wrap {
  display: flex;
}
.button-wrap > button {
  flex: 1;
  margin-right: 4px;
}
.button-wrap > button:last-child {
  margin-right: 0;
}
.input[type="checkbox"] + label > span {
  margin-left: 4px !important;
}
.bottom-wrap td{
  border:0;
  border-bottom:solid 1px #ddd;
}
.user-wrapper-outer > .bottom-wrap {
    overflow-y: scroll;
    height: 606px;
}
.bottom-wrap::-webkit-scrollbar{
  width:4px;
  background: transparent;
  right:10px;
  border-radius: 4px;
}
.bottom-wrap::-webkit-scrollbar-thumb{
  background:#B26E75;
  border-radius: 8px;
}
.bottom-wrap::-webkit-scrollbar-track{
  background: #f5f5f5;
}
.fa-check{
  margin-right:5px;
}
.fa-check:last-child{
  margin-right:0;
}
.search-check-wrap{
  margin:0;
}
</style>
